import { init, RematchRootState, RematchDispatch } from '@rematch/core';
import { useDispatch } from 'react-redux';

import persist from '@rematch/persist';

import localForage from 'localforage';

import { models, RootModel } from './models';
import { EVENTCODE } from '../configs/active/constants';

export const store = init<RootModel>({
	models,
	plugins: [
		persist({
			key: EVENTCODE,
			storage: localForage,
			blacklist: ['socket'],
			version: 1,
		}),
	],
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type IRootState = RematchRootState<RootModel>;

export const useRematchDispatch = <D extends {}, MD>(selector: (dispatch: D) => MD) => {
	const dispatch = useDispatch<D>();
	return selector(dispatch);
};
