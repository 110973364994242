import { IKVP } from 'configs/interfaces';

export const REGION = 'europe-west3';

export const EVENTTIMEZONE = 'Europe/Berlin';

export const firebaseConfig = {
	apiKey: 'AIzaSyBix00bDuI_0UasyecnPT271dUMY-Ple3g',
	authDomain: 'bits22.firebaseapp.com',
	projectId: 'bits22',
	storageBucket: 'bits22.appspot.com',
	messagingSenderId: '610929323812',
	appId: '1:610929323812:web:22476d1c48904face94db1',
};

export const RESOLVETOKENURL = 'https://europe-west3-bits22.cloudfunctions.net/resolveUserToken';
export const BOOKSLOTURL = 'https://europe-west3-bits22.cloudfunctions.net/bookSlot';
export const CHECKTICKETCODEURL = 'https://europe-west3-bits22.cloudfunctions.net/checkTicketCode';
export const CANCELSLOTURL = 'https://europe-west3-bits22.cloudfunctions.net/cancelSlot';

export const numberOfSlots = 2;
export const maximumNumberOfTableCaptains = 2;
export const EVENTCODE = 'bits24';
export const APPLICATIONURL = 'https://captain.bitsandpretzels.com';
export const EVENTTITLE = 'Bits & Pretzels - Table Captain Booking';
export const FAVICONURL = 'https://hspxde.blob.core.windows.net/bits22/assets/bitslogo_c765a88e2b.svg';

export const CATEGORY2FILTERS: IKVP[] = [
	{
		key: 'tablecaptain',
		label: 'Table Captains',
	},
	{
		key: 'topictable',
		label: 'Topic Tables',
	},
];

export const CATEGORY3FILTERS: IKVP[] = [
	// {
	// 	key: 'All',
	// 	label: 'All',
	// },
	{
		key: 'Founder',
		label: 'Founders',
	},
	{
		key: 'Investor',
		label: 'Investors',
	},
	{
		key: 'Corporate',
		label: 'Corporates',
	},
	{
		key: 'Media',
		label: 'Media',
	},
];

export const teamCategory4: IKVP[] = [
	{
		key: 'Bitcoin & Blockchain',
		label: 'Bitcoin & Blockchain',
	},
	{
		key: 'Creator Economy',
		label: 'Creator Economy',
	},
	{
		key: 'Food Tech',
		label: 'Food Tech',
	},
	{
		key: 'Scaling SaaS',
		label: 'Scaling SaaS',
	},
	{
		key: 'Investment Trends & Emerging Markets',
		label: 'Investment Trends & Emerging Markets',
	},
	{
		key: 'Social Entrepreneurship',
		label: 'Social Entrepreneurship',
	},
	{
		key: 'Future of Work',
		label: 'Future of Work',
	},
	{
		key: 'Leadership',
		label: 'Leadership',
	},
	{
		key: 'Personal Branding',
		label: 'Personal Branding',
	},
	{
		key: 'Female Founder Networking',
		label: 'Female Founder Networking',
	},
	{
		key: 'Founder Mental Health',
		label: 'Founder Mental Health',
	},
	{
		key: 'Looking for Co-Founders',
		label: 'Looking for Co-Founders',
	},
	{
		key: 'Student Entrepreneurship',
		label: 'Student Entrepreneurship',
	},
];

export const slotTimes = [
	{
		startDate: '01.10.2024 10:45',
		endDate: '01.10.2024 11:30',
	},
	{
		startDate: '01.10.2024 11:45',
		endDate: '01.10.2024 12:30',
	},
];
